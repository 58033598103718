import React, { useEffect, useCallback } from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import { useTheme } from "./contexts/ThemeContext";
import SessionExpired from "./pages/Errors/SessionExpired";
import Forbidden from './pages/Errors/Forbidden';
import AdvisorsIndex from "./pages/Advisors/Index";
import ConsumersIndex from "./pages/Consumers/Index";
import ConsumerForm from "./pages/Consumers/Create";
import ConsumerProfile from "./pages/Consumers/Profile";
import LongevityReport from './pages/Reports/Longevity/Report';
import LongevityPreview from "./pages/Reports/Longevity/Preview";
import InternalRateOfReturnEdit from "./pages/Reports/InternalRateOfReturn/Edit";
import InternalRateOfReturnReport from "./pages/Reports/InternalRateOfReturn/Report";
import InternalRateOfReturnPreview from "./pages/Reports/InternalRateOfReturn/Preview";
import SequenceOfReturnsEdit from "./pages/Reports/SequenceOfReturns/Edit";
import SequenceOfReturnsPreview from "./pages/Reports/SequenceOfReturns/Preview";
import SequenceOfReturnsReport from "./pages/Reports/SequenceOfReturns/Report";
import InflationEdit from "./pages/Reports/Inflation/Edit";
import InflationPreview from "./pages/Reports/Inflation/Preview";
import InflationReport from "./pages/Reports/Inflation/Report";
import SuperRebalancerEdit from "./pages/Reports/SuperRebalancer/Edit";
import SuperRebalancerPreview from "./pages/Reports/SuperRebalancer/Preview";
import SuperRebalancerReport from "./pages/Reports/SuperRebalancer/Report";
import dataCalls from './shared/dataCalls';
import axios from "axios";
import NotFound from './pages/Errors/NotFound';
import Activities from "./pages/Activities/Index";
import Example from './pages/Admin/Example';
import Analytics from './pages/Analytics/Analytics';

export default function SubApp() {

  const navigate = useNavigate();
  const { backgroundClass, justifyClass } = useTheme();

  // Add a response interceptor
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  axios.interceptors.request.use(function (config) {
    console.log("Request: ");
    console.log(config);
    return config;
  });

  axios.interceptors.response.use(function (response) {
    console.log("Response: ");
    console.log(response);
    if(response.isError) {
      return {
        success: false,
        data: null
      }
    }
    return {
      success: true,
      data: response.data
    };
  }, function (error) {
      if(error.response) {
        if(error.response.status === 401) {
            navigate("/v1/unauthorized");
        }
        if(error.response.status === 403) {
            navigate("/v1/forbidden");
        }
        if(error.response.status === 404) {
            navigate("/v1/notfound");
        }
      }
      return {
        isError: true
      }
  });

  const handleActivityCreation = useCallback(async () => {
    await dataCalls.logActivity(window.location.pathname + window.location.search);
  }, [window.location.pathname])

  useEffect(() => {
    handleActivityCreation();
  }, [handleActivityCreation])


  return (
      <div id="subApp" className={`${backgroundClass} ${justifyClass} flex flex-col items-center h-app w-full`}>
        <Routes>
              <Route path="/v1/unauthorized" element={<SessionExpired/>}></Route>
              <Route path="/v1/forbidden" element={<Forbidden/>}></Route>
              <Route path="/v1/notfound" element={<NotFound />}></Route>
          <Route path="*" element={<NotFound />}></Route>

              <Route exact path="/v1/example" element={<Example />}></Route>
              <Route exact path="/v1/analytics" element={<Analytics />}></Route>
              <Route exact path="/v1/activities" element={<Activities />}></Route>

              <Route path="/v1/advisors" element={<AdvisorsIndex/>}></Route>
          <Route exact path="/v1/consumers" element={<ConsumersIndex/>}></Route>
              <Route exact path="/v1/consumers/:id" element={<ConsumerProfile/>}></Route>
              <Route exact path="/v1/consumers/create" element={<ConsumerForm/>}></Route>
              <Route exact path="/v1/consumers/edit" element={<ConsumerForm/>}></Route>

              <Route exact path="/v1/reports/longevity/create/:id" element={<LongevityPreview/>}></Route>
              <Route exact path="/v1/reports/longevity/create/:id/:spouseid" element={<LongevityPreview/>}></Route>
              <Route exact path="/v1/reports/longevity/report/:id" element={<LongevityReport/>}></Route>

              <Route exact path="/v1/reports/internalrateofreturn/create/:id" element={<InternalRateOfReturnEdit/>}></Route>
              <Route exact path="/v1/reports/internalrateofreturn/create/:id/:spouseid" element={<InternalRateOfReturnEdit/>}></Route>
              <Route exact path="/v1/reports/internalrateofreturn/edit/:id" element={<InternalRateOfReturnEdit/>}></Route>
              <Route exact path="/v1/reports/internalrateofreturn/preview/:id" element={<InternalRateOfReturnPreview/>}></Route>
              <Route exact path="/v1/reports/internalrateofreturn/report/:id" element={<InternalRateOfReturnReport/>}></Route>

              <Route exact path="/v1/reports/sequenceofreturns/create/:id" element={<SequenceOfReturnsEdit/>}></Route>
              <Route exact path="/v1/reports/sequenceofreturns/create/:id/:spouseid" element={<SequenceOfReturnsEdit/>}></Route>
              <Route exact path="/v1/reports/sequenceofreturns/edit/:id" element={<SequenceOfReturnsEdit/>}></Route>
              <Route exact path="/v1/reports/sequenceofreturns/preview/:id" element={<SequenceOfReturnsPreview/>}></Route>
              <Route exact path="/v1/reports/sequenceofreturns/report/:id" element={<SequenceOfReturnsReport />}></Route>

              <Route exact path="/v1/reports/inflation/create/:id" element={<InflationEdit />}></Route>
              <Route exact path="/v1/reports/inflation/create/:id/:spouseid" element={<InflationEdit />}></Route>
              <Route exact path="/v1/reports/inflation/edit/:id" element={<InflationEdit />}></Route>
              <Route exact path="/v1/reports/inflation/preview/:id" element={<InflationPreview />}></Route>
              <Route exact path="/v1/reports/inflation/report/:id" element={<InflationReport />}></Route>

              <Route exact path="/v1/reports/superrebalancer/create/:id" element={<SuperRebalancerEdit />}></Route>
              <Route exact path="/v1/reports/superrebalancer/create/:id/:spouseid" element={<SuperRebalancerEdit />}></Route>
              <Route exact path="/v1/reports/superrebalancer/edit/:id" element={<SuperRebalancerEdit />}></Route>
              <Route exact path="/v1/reports/superrebalancer/preview/:id" element={<SuperRebalancerPreview />}></Route>
              <Route exact path="/v1/reports/superrebalancer/report/:id" element={<SuperRebalancerReport />}></Route>
        </Routes>
    </div>
  )
}
