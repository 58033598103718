import React from 'react'
import PropTypes from 'prop-types';
import utils from '../../../../shared/utils';
import "../../../../styles/reports/superrebalancer/edit.css";
import shared from '../../../../scripts/reports/internalrateofreturn/shared';

ProjectionTable.propTypes = {
  ledger: PropTypes.array.isRequired,
  viewModel: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
}

ProjectionTable.defaultProps = {
    isPreview: false
};

export default function ProjectionTable(props) {

    let hasSpouse = props.ledger[0].spouseAge !== 0;
    let ageText = hasSpouse ? "Ages" : "Age";

  return (
    

      <table className="text-sm w-[99%]">
          <thead className="bg-slate-200">
              <tr>
                  <th colSpan="2" className="bg-white"></th>
                  <th colSpan="3" className="border-b-2 border-slate-300"> Baseline</th>
                  <th className="border-l-2 border-b-2 border-slate-300" colSpan="5"> Annuity Income</th>
              </tr>
              <tr>
                  <th>
                  Year
                  </th>
                  <th>
                      {ageText}
                  </th>
                  <th className="border-l-2 border-slate-300">
                  BoY
                  </th>
                  <th>
                  Income
                  </th>
                  <th>
                  EoY
                  </th>
                  <th className="border-l-2 border-slate-300">
                  BoY
                  </th>
                  <th>
                  Income
                  </th>
                  <th>
                      EoY
                  </th>
                  <th>
                      Rebalance EoY
                  </th>
                  <th>
                      Reposition EoY
                  </th>
              </tr>

          </thead>
          <tbody className="text-right text-[10px]">
                  {props.ledger?.length > 0 && (
                  props.ledger?.map((entry) => (
                      <tr key={entry.year} className={shared.getLedgerRowBackgroundClass(entry, props.viewModel, "")}>
                          <td className="text-center">
                                {entry.year}
                          </td>
                          <td className="text-center">
                              {entry.clientAge}{hasSpouse ? "/" + entry.spouseAge : ""}
                          </td>
                          <td className="border-l-2 border-slate-300">
                                {entry.baselineBalanceBeginningOfYearString}
                              </td>
                              <td>
                                {entry.baselineIncomeString}
                              </td>
                              <td>
                                {entry.baselineBalanceEndOfYearString}
                          </td>
                          <td className="border-l-2 border-slate-300">
                                {entry.annuityIncomeBalanceBeginningOfYearString}
                              </td>
                              <td>
                                {entry.annuityIncomeIncomeString}
                              </td>
                              <td>
                                {entry.annuityIncomeBalanceEndOfYearString}
                              </td>
                              <td>
                                {entry.annuityIncomeBalanceRebalanceExcessEndOfYearString}
                              </td>
                              <td>
                                {entry.annuityIncomeBalanceRepositionExcessEndOfYearString}
                                          </td>
                              </tr>
                          //<tr key={entry.year} className={shared.getLedgerRowBackgroundClass(entry, props.viewModel, "")}>
                          //    <td className="text-center">{entry.year}</td>
                          //    <td className="text-center">{entry.clientAge}</td>
                          //    {props.viewModel.spouse && (<td className="text-center">{entry.spouseAge}</td>)}
                          //    {!props.isPreview && <td className="text-right">{utils.formatAsCurrency(entry.annualIncome, 0)}</td>}
                          //    {!props.isPreview && <td className="text-right">{entry.assumedInflationPercentageString}</td>}
                          //    {!props.isPreview && <td className="text-right">{entry.inflationImpactPercentageString}</td>}
                          //    {!props.isPreview && <td className="text-right pr-2">{utils.formatAsCurrency(entry.spendingPower, 0)}</td>}
                          //</tr>
                      )))}
          </tbody>
      
      
      </table>
  )
}
