import axios from "axios";

const postConfig = {
    headers: {
        'Content-Type': 'application/json'
    },
};

class remoteCalculations {
    //Get Life Expectancy
    static getLifeExpectancies = async (age, isMale) => {
        const response = await axios.get(`/api/calculations/lifeexpectancies?age=${age}&isMale=${isMale}`);
        return await response.data;
    }

    //IRR calcs
    static getInternalRateOfReturnLedger = async (reportData) => {
        const response = await axios.post(`/api/calculations/internalrateofreturn`, reportData, postConfig);
        return await response.data;
    }

    //Sequence of Returns calcs
    static getSequenceOfReturnsLedger = async (reportData) => {
        const response = await axios.post(`/api/calculations/sequenceofreturns`, reportData, postConfig);
        return await response.data;
    }

    //Inflation calcs
    static getInflationLedger = async (reportData) => {
        const response = await axios.post(`/api/calculations/inflation`, reportData, postConfig);
        return await response.data;
    }

    //Super Rebalancer calcs
    static getSuperRebalancerLedger = async (reportData) => {
        const response = await axios.post(`/api/calculations/superrebalancer`, reportData, postConfig);
        return await response.data;
    }
}

export default remoteCalculations;