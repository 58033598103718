import React, { useEffect, useState, useCallback } from "react";
import { useQuery } from 'react-query';
import { useForm, FormProvider } from "react-hook-form";
import { useTheme } from '../../../contexts/ThemeContext';
import BackButton from "../../../components/BackButton";
import Skeleton from 'react-loading-skeleton';
import staticData from '../../../shared/staticData';
import dataCalls from "../../../shared/dataCalls";
import remoteCalculations from "../../../shared/remoteCalculations";
import globalSettings from "../../../shared/settings";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { ExclamationCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import ProjectionTable from "./Components/ProjectionTable";
import Input from "../../../inputs/Input";
import Button from "../../../inputs/Button";
import "../../../styles/reports/shared/shared.css";
import utils from "../../../shared/utils";
import "../../../styles/reports/superrebalancer/edit.css";
import "../../../styles/reports/superrebalancer/table.css";

export default function Edit() {
    const settings = {
        isEdit: window.location.pathname.includes("edit")
    };

    const [calculationResults, setCalculationResults] = useState({});
    const [triggerCalculationUpdate, setTriggerCalculationUpdate] = useState(false);
    const [formErrors, setFormErrors] = useState({
        errors: [],
        incomeOverlapErrorIndices: [],
        inflationPeriodErrorIndices: []
    });

    const formMethods = useForm();

    const params = useParams();
    const navigate = useNavigate();

    const fetchMethod = settings.isEdit
        ? () => dataCalls.fetchSuperRebalancerViewModelEdit(params.id)
        : () => dataCalls.fetchSuperRebalancerViewModelCreate(params.id, params.spouseid);

    const { data: viewModel, isLoading, refetch } = useQuery('vm', fetchMethod, {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: Infinity
    });

    useEffect(() => {
        if (settings.isEdit) {
            refetch();
        }
    }, [params.id])

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        return () => resetTheme();
    }, []);

    //Set initial form state on viewmodel load
    useEffect(() => {
        if (viewModel && (settings.isEdit && viewModel.report || !settings.isEdit)) {
            formMethods.setValue("reportName", viewModel.report.reportName);
            formMethods.reset(settings.isEdit
                ? viewModel.report.reportData
                : viewModel.defaultReportData);
            if (isValid()) setTriggerCalculationUpdate(true);
        }
    }, [viewModel])

    const updateCalculations = useCallback(async () => {
        if (triggerCalculationUpdate && viewModel) {
            const data = formMethods.getValues();
            const scenario = {
                clientAge: viewModel.client.age,
                spouseAge: viewModel.spouse?.age ?? 0,
                reportData: JSON.parse(JSON.stringify(data))
            };
            const results = await remoteCalculations.getSuperRebalancerLedger(scenario);
            setCalculationResults(results);
        }
        setTriggerCalculationUpdate(false);
    }, [triggerCalculationUpdate]);

    useEffect(() => {
        updateCalculations();
    }, [updateCalculations]);

    const updateFormDataItem = ({ name, value }, updateCalculations = true, validateBasicInputs = false) => {
        formMethods.setValue(name, value);
        if (isValid(validateBasicInputs) && updateCalculations) setTriggerCalculationUpdate(true);
    }

    const onSubmit = async (data) => {
        await handleEditSubmit(viewModel, data, toast, navigate);
    }

    const handleEditSubmit = async (viewModel, data, toast, navigate) => {
        if (!isValid(true)) {
            toast.error("Correct the form errors before continuing.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }
        let report = viewModel.report;
        report.reportName = data.reportName;
        delete data.reportName;
        report.reportData = data;

        //Save and Preview
        const toastId = toast.loading("Saving report...", {
            position: toast.POSITION.BOTTOM_CENTER
        });
        const response = await dataCalls.saveDraft(report);
        if (response.success) {
            toast.update(toastId, { render: "Save successful", type: "success", isLoading: false });
            setTimeout(() => navigate(`/v1/reports/superrebalancer/preview/${response.data}`), 1000);
        } else {
            toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
        }
        setTimeout(() => toast.dismiss(toastId.current), 1000);
        return response.success;
    }

    const isValid = (includeBasicInputs = false) => {
        const data = formMethods.getValues();
        const validationErrors = buildValidationErrors(data, includeBasicInputs);
        setFormErrors(validationErrors);
        return validationErrors.errors.length === 0 && isScenarioComplete(data);
    }

    const hasFieldError = (fieldName) => {
        return formErrors.errors.filter(e => e.name === fieldName).length > 0;
    }

    const isScenarioComplete = (data) => {
        return data.numberOfYears > 0;
    }

    const buildValidationErrors = (form, includeBasicInputs = false) => {
        let errorObjects = [];

        if (includeBasicInputs) {
            if (form.reportName?.length === 0) {
                errorObjects.push({
                    name: "reportName",
                    message: "Report must be named."
                });
            }
        }

        if (form.numberOfYears < 1) {
                errorObjects.push({
                    name: "numberOfYears",
                    message: "Number of years must be greater than or equal to 1."
                });
            }
        if (form.numberOfYears > 100) {
                errorObjects.push({
                    name: "numberOfYears",
                    message: "Number of years must be less than or equal to 100."
                });
        }

        if (form.startingBalance > 1000000000) {
            errorObjects.push({
                name: "startingBalance",
                message: "Starting Balance must be less than or equal to $1,000,000,000."
            });
        }
        if (form.startingBalance < -1000000000) {
            errorObjects.push({
                name: "startingBalance",
                message: "Starting Balance must be greater than or equal to -$1,000,000,000."
            });
        }

        if (form.baselineReturnInterest > 100) { // 1a
            errorObjects.push({
                name: "baselineReturnInterest",
                message: "The baseline return interest rate must be less than or equal to 100%."
            });
        }
        if (form.baselineReturnInterest < 1) { //1b
            errorObjects.push({
                name: "baselineReturnInterest",
                message: "The baseline return interest rate must be greater than 0%."
            });
        }

        if (form.baselineIncomePercentage > 100) { // 2a
            errorObjects.push({
                name: "baselineIncomePercentage",
                message: "The baseline income rate must be less than or equal to 100%."
            });
        }
        if (form.baselineIncomePercentage < 1) { // 2b
            errorObjects.push({
                name: "baselineIncomePercentage",
                message: "The baseline income rate must be greater than 0%."
            });
        }

        if (form.annuityIncomeInterest > 100) { // 3a
            errorObjects.push({
                name: "annuityIncomeInterest",
                message: "The annuity income interest rate must be less than or equal to 100%."
            });
        }
        if (form.annuityIncomeInterest < 1) { // 3b
            errorObjects.push({
                name: "annuityIncomeInterest",
                message: "The annuity income interest rate must be greater than 0%."
            });
        }

        if (form.annuityIncomeIncomePercentage > 100) { // 4a
            errorObjects.push({
                name: "annuityIncomeIncomePercentage",
                message: "The annuity income income's rate must be less than or equal to 100%."
            });
        }
        if (form.annuityIncomeIncomePercentage < 1) { // 4b
            errorObjects.push({
                name: "annuityIncomeIncomePercentage",
                message: "The annuity income income's rate must be greater than 0%."
            });
        }

        if (form.annuityIncomeRepositionExcess > 100) { // 5a
            errorObjects.push({
                name: "annuityIncomeRepositionExcess",
                message: "The annuity income reposition excess rate must be less than or equal to 100%."
            });
        }
        if (form.annuityIncomeRepositionExcess < 1) { // 5b
            errorObjects.push({
                name: "annuityIncomeRepositionExcess",
                message: "The annuity income reposition excess rate must be greater than 0%."
            });
        }

        return {
            errors: errorObjects,
        }
    }

    const backToEditMethod = () => {
        if (settings.isEdit && !viewModel) navigate(-1);
        settings.isEdit
            ? navigate(`/v1/consumers/${viewModel.client.id}`)
            : navigate(`/v1/consumers/${params.id}`);
    }
        return (
            <div className="justify-center place-content-center w-[82%] mt-6 min-h-full">
                <div className="grid grid-cols-4">
                    <div className="block col-span-3">
                        <BackButton text="Back to Client Profile" onClick={backToEditMethod} />
                        {isLoading
                            ? <h3><Skeleton /></h3>
                            : <h3>Super Rebalancer Report for {viewModel?.names}</h3>
                        }
                    </div>
                    <div className="w-full flex justify-end items-center pr-4">
                        <img alt="Super Rebalancer Logo" src={staticData.getReport(7).imgSrc} className="w-16 h-16"></img>
                    </div>
                </div>
                <div className="grid grid-cols-[40%,60%]">
                        <div className="mr-2" style={{ marginBottom: '5%' }}>
                            <FormProvider {...formMethods}>
                                <form autoComplete="off">
                                    <div className="">
                                        <Input
                                            type="text"
                                            name="reportName"
                                            label={"Report Name"}
                                            onChange={(e) => updateFormDataItem(e.target, false, true)}
                                            containerClassNames={"py-3"}
                                            hasValidationError={hasFieldError("reportName")}
                                            autoFocus={true}
                                        />

                                        <div className="bg-slate-200 mt-6 p-4">
                                            <div className="grid">
                                                <div className="flex justify-between">
                                                    <span className="font-bold">Scenario</span>
                                                </div>
                                                <span className="text-sm">Enter the basic details of the plan</span>
                                                <Input
                                                    type="number"
                                                    step="1"
                                                    name="numberOfYears"
                                                label={"Number Of Years"}
                                                onInput={(e) => {
                                                    e.target.value = Math.round(e.target.value);
                                                }
                                                }
                                                onChange={(e) => updateFormDataItem(e.target)}
                                                conditionalLabel={`(Maximum of ${globalSettings.superRebalancerMaxLedgerLength} years per page)`}
                                                showConditionalLabel={formMethods.getValues("numberOfYears") > globalSettings.superRebalancerMaxLedgerLength}
                                                    containerClassNames={"py-3"}
                                                    hasValidationError={hasFieldError("numberOfYears")}
                                            />

                                            <Input
                                                type="number"
                                                name="startingBalance"
                                                label={"Starting Balance"}
                                                symbol="$"
                                                onInput={(e) => {
                                                    e.target.value = Math.round(e.target.value);
                                                }
                                                }
                                                onChange={(e) => updateFormDataItem(e.target)}
                                                containerClassNames={"py-3 mr-1 w-1/2"}
                                                hasValidationError={hasFieldError("startingBalance")}
                                            />
                                        </div>
                                            </div>

                                        <div className="bg-slate-200 mt-6 p-4">
                                        <div className="flex justify-between">
                                            <span className="font-bold">Baseline</span>
                                        </div>
                                            <div className="flex">
                                            <Input
                                                type="number"
                                                name="baselineReturnInterest"
                                                label={"Return Interest / Rebalance Excess"}
                                                symbol="%"
                                                onInput={(e) => {
                                                    utils.setDecimalPlaces(e);
                                                }}
                                                onChange={(e) => updateFormDataItem(e.target)}
                                                containerClassNames={"py-3 mr-1 w-1/2"}
                                                hasValidationError={hasFieldError("baselineReturnInterest")}
                                            />
                                            <Input
                                                type="number"
                                                name="baselineIncomePercentage"
                                                label={"Income"}
                                                symbol="%"
                                                onInput={(e) => {
                                                    utils.setDecimalPlaces(e);
                                                }}
                                                onChange={(e) => updateFormDataItem(e.target)}
                                                containerClassNames={"py-3 ml-1 w-1/2"}
                                                hasValidationError={hasFieldError("baselineIncomePercentage")}
                                            />
                                            </div>
                                            </div>
                                            <div className="bg-slate-200 mt-6 p-4">
                                        <div className="flex justify-between">
                                            <span className="font-bold">Annuity Income</span>
                                        </div>
                                            <div className="flex">
                                            <Input
                                                type="number"
                                                name="annuityIncomeInterest"
                                                label={"Interest"}
                                                symbol="%"
                                                onInput={(e) => {
                                                    utils.setDecimalPlaces(e);
                                                }}
                                                onChange={(e) => updateFormDataItem(e.target)}
                                                containerClassNames={"py-3 mr-1 w-1/2"}
                                                hasValidationError={hasFieldError("annuityIncomeInterest")}
                                            />
                                            <Input
                                                type="number"
                                                name="annuityIncomeIncomePercentage"
                                                label={"Income"}
                                                symbol="%"
                                                onInput={(e) => {
                                                    utils.setDecimalPlaces(e);
                                                }}
                                                onChange={(e) => updateFormDataItem(e.target)}
                                                containerClassNames={"py-3 ml-1 w-1/2"}
                                                hasValidationError={hasFieldError("annuityIncomeIncomePercentage")}
                                                />
                                            </div>
                                        <div className="flex">
                                                <Input
                                                    type="number"
                                                    name="annuityIncomeRepositionExcess"
                                                    label={"Reposition Excess"}
                                                    symbol="%"
                                                    onInput={(e) => {
                                                        utils.setDecimalPlaces(e);
                                                    }}
                                                    onChange={(e) => updateFormDataItem(e.target)}
                                                    containerClassNames={"py-3 mr-1 w-1/2"}
                                                    hasValidationError={hasFieldError("annuityIncomeRepositionExcess")}
                                                />
                                            </div>
                                            </div>
                                    </div>
                                    <div className="flex justify-end mt-4">
                                        <Button clickHandler={formMethods.handleSubmit((data) => onSubmit(data))} text="Save and Preview" classNames="bg-core-orange-400 ml-1" />
                                    </div>
                                </form>
                            </FormProvider>
                        </div>

                        <div className="ml-2">
                            <div className="flex justify-center">
                                <span className="font-bold">Report Preview</span>
                            </div>
                            <div className={`${formErrors.errors.length > 0 ? "blur-children" : ""} bg-white min-h-max ml-2 mb-4 relative`}>
                                {formErrors.errors.length > 0 && (
                                    <div className="shadow-md rounded-lg preview-error-container unblur">
                                        <div className="p-4">
                                            <div className="flex items-center">
                                                <ExclamationCircleIcon className="w-7 h-7 text-red-500"></ExclamationCircleIcon>
                                                <span className="ml-2 font-bold">Form Errors</span>
                                            </div>
                                            <div className="block">
                                                <div>Resolve the errors to generate a preview</div>
                                                <ul className="ml-4">
                                                    {formErrors.errors.map((error, i) => (
                                                        <li key={i} className="text-sm list-disc">{error.message}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="p-4">
                                    <div className="flex justify-between header-container">
                                        <div className='preview-title'>
                                            <span className="font-bold">Super Rebalance</span>
                                            <br />
                                        </div>
                                    </div>
                                    {viewModel && calculationResults?.ledger && <ProjectionTable ledger={calculationResults.ledger} viewModel={viewModel} />}
                                </div>
                            </div>
                        </div>
                    </div>
                <ToastContainer />
            </div>
        )
    }
